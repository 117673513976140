import React from "react"
import Layout from "../components/layout/layout"
import TableOfContents from "../components/homepage/table-of-contents"
import SEO from "../components/layout/seo"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, withPrefix, Link } from "gatsby"
import Slider from "../components/slider";

export default function Homepage({ data }) {
  const seo = {
    title: "Przychodnia specjalistyczna Medicus-Dukla. Prywatnie i NFZ",
    description: "Poradnie: Podstawowa opieka zdrowotna / Poradnie specjalistyczne (NFZ) / Poradnie specjalistyczne prywatne. Diagnostyka. Usługi: Opieka Pielęgniarska Długoterminowa | Hospicjum domowe | Medycyna Pracy | Badania Kierowców – wszystkie kategorie | Transport sanitarny",
    url: "https://medicusdukla.pl/"
  }

  let articles = data.blog.edges.map(({ node }) => ({
    fields: node.fields,
    html: node.html,
    ...node.frontmatter
  }))

  articles.sort(function(a, b) {
    const dateA = a.date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2.$1.$3").split('.')
    const dateB = b.date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2.$1.$3").split('.')
    return new Date(dateB[2], dateB[0], dateB[1]) - new Date(dateA[2], dateA[0], dateA[1])
  })

  articles = articles.slice(0, 3)

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <TableOfContents />
      <section className="o-homepage-hero">
        <Slider />
        <div data-g="container grid vertical-end">
          <div data-g="4 6@md">
            <h1 className="a-heading-big">Z myślą o naszych pacjentach</h1>
            <p className="a-paragraph">
              Przeszliśmy drogę rozwoju poszerzając systematycznie zakres świadczonych usług o poradnię stomatologiczną,
              hospicjum domowe oraz własny transport sanitarny.
            </p>
            <div className="logos" style={{display: 'flex', alignItems: 'center'}}>
              <StaticImage className="a-img  a-img--funds" src="../images/homepage/logos/funds.png"
                           alt="fundusze europejskie" quality={80} formats={['auto', 'webp', 'avif']}/>
              <StaticImage className="a-img  a-img--flag" src="../images/homepage/logos/flaga.png"
                           alt="Flaga Rzeczpospolitej Polskiej" quality={80} formats={['auto', 'webp', 'avif']}/>
              <StaticImage className="a-img a-img--podkarpackie" src="../images/homepage/logos/podkarpackie.png"
                           alt="Podkarpackie, unia europejska" quality={80} formats={['auto', 'webp', 'avif']}/>
            </div>
            <br/>
            <br/>
            <a href="https://psim.podkarpackie.pl/strona-glowna" target="_blank" rel="nofollow">
              <StaticImage src="../images/homepage/psim-baner.jpeg" alt="psim" quality={80} formats={['auto', 'webp', 'avif']}/>
            </a>
            <div className="homepage-button">
              <a className="a-button" href="https://psim.podkarpackie.pl/strona-glowna" target="_blank" rel="nofollow">
                Rejestracja online
              </a>
              <div className={"nfz-icon"}>
                <img className="a-img" src="/images/homepage/nfz_only.png" alt="nfz" data-g="hide@md" />
                <div>
                  Świadczymy usługi <br />
                w ramach kontraktu z NFZ.
                </div>
              </div>
            </div>
            <div data-g="hide show@md">
              <img className="a-img nfz" src="/images/homepage/nfz.png" alt="nfz" data-g="hide show@md" />
            </div>
          </div>
          <div data-g="4 6@md">
            <StaticImage className="a-img" src="../images/homepage/hero.png" alt="zdjęcie lekarza" quality={80} formats={['auto', 'webp', 'avif']}/>
          </div>
        </div>
      </section>
      <section className="o-homepage-text">
        <div data-g="container grid ">
          <div data-g="4 2@md">
            <img className="a-img" alt="decoration" src={withPrefix("images/homepage/decoration-1.svg")}
                 data-g="hide show@md" />
          </div>
          <div data-g="4 8@md">
            <p>
              Opiekę zdrowotną naszym pacjentom zapewnia doświadczony zespół medyczny przy wykorzystaniu nowoczesnego
              sprzętu. Naszą misją jest ciągłe doskonalenie w świadczeniu usług zdrowotnych.
            </p>
          </div>
        </div>
      </section>
      <section className="o-homepage-content">
        <div data-g="container grid">
          <div data-g="2 3@md" className="homepage-content-box">
            <Link to="/poradnie/poradnia-stomatologiczna/" className="u-absolute-link"></Link>
            <img className="a-img" alt="decoration" src={withPrefix("images/homepage/stomatologia.svg")} />
            <div>Stomatologia</div>
          </div>
          <div data-g="2 3@md" className="homepage-content-box">
            <Link to="/diagnostyka/" className="u-absolute-link"></Link>
            <img className="a-img" alt="decoration" src={withPrefix("images/homepage/diagnostyka.svg")} />
            <div>Diagnostyka</div>
          </div>
          <div data-g="2 3@md" className="homepage-content-box">
            <Link to="/uslugi/" className="u-absolute-link"></Link>
            <img className="a-img" alt="decoration" src={withPrefix("images/homepage/dom-dzienny.svg")} />
            <div>Dom Dzienny</div>
          </div>
          <div data-g="2 3@md" className="homepage-content-box">
            <Link to="/ddom/" className="u-absolute-link"></Link>
            <img className="a-img" alt="decoration" src={withPrefix("images/homepage/badania-kierowcow.svg")} />
            <div>Badania kierowców</div>
          </div>
          {/*<div data-g="4 12@md" className="homepage-content-button">*/}
          {/*  <a className="a-button" href="TODO" target="_blank" rel="nofollow">*/}
          {/*    Umów wizytę*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
      </section>
      <section className="o-homepage-tradition">
        <div data-g="container grid">
          <div data-g="4 3@md offset-2@lg 3@lg hide show@md">
            <img className="a-img" alt="decoration" src={withPrefix("images/homepage/decoration-2.svg")} />
          </div>
          <div data-g="4 offset-5@md 7@md offset-7@lg 4@lg">
            <h2>
              Przychodnia z tradycją
            </h2>
            <p>
              Medicus Dukla to poradnia z wieloletnią tradycją. Chcemy by nasi pacjenci mogli być spokojni o swoje
              zdrowie, dlatego oferujemy kompleksowe usługi medyczne. Twoje zdrowie to nasza misja. Jeżeli potrzebujesz
              fachowej opieki lub specjalistycznych badań skontaktuj się z nami już dziś i miej pewność świadczonej
              usługi dzięki naszej umowie z NFZ.
            </p>
            <a href="/o-nas" className="a-button-border">
              Więcej o nas
            </a>
          </div>
        </div>
      </section>
      <section className="o-homepage-articles">
        <div data-g="container" className="u-position-relative">
          <h2>
            Co u nas słychać?
          </h2>
          <Link to="/aktualnosci/" className="more-articles">
            Zobacz wszystko <img src={withPrefix("images/homepage/arrow-right.svg")} alt="arrow right" />
          </Link>
          <div data-g="grid">
            {
              articles.map(article => (
                <div data-g="2 4@md" className="u-position-relative article">
                  <Link to={article.slug} className="u-absolute-link" />
                  <GatsbyImage className="a-img" image={getImage(article.fields.background)} alt={article.title} />
                  <div className="date">
                    {article.date}
                  </div>
                  <div className="title">
                    {article.title}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query {
        blog: allMarkdownRemark  {
            edges {
                node {
                    html
                    frontmatter {
                        title
                        date
                        slug
                        excerpt
                    }
                    fields {
                        background {
                            childImageSharp {
                                gatsbyImageData(width: 1920, quality: 80, formats: [AUTO, WEBP, AVIF])
                            }
                        }
                    }
                }
            }
        }
    }
`
